<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
    <!-- Start Page Content -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div id="report" class="card-body">
            <div class="container-fluid">
              <div style="width: 80px;">
                <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;" />
              </div>
              <div class="headerreport">
                <div class="row">
                  {{ companydata.cmp_nmbre }}
                </div>
                <div class="row">
                  {{ fecha }}
                </div>
              </div>
            </div>
            <br>
            <div class="card-title">
              <h1 class="titlereport">Reporte de Archivos adjuntos faltantes</h1>
              <h5 class="subtitle">Sala: {{ sal_nmbre }}</h5>
            </div>
            <div id="wrapping" class="table-responsive m-t-40 bodyreport">
              <table id="reporteAdjuntosFaltantes" class="table table-sm display nowrap table-striped table-bordered"
                cellspacing="0" width="100%">
                <thead>
                  <tr>
                    <th>Estante</th>
                    <th>Caja</th>
                    <th>Inventario</th>
                    <th>Documento</th>
                    <th>Nombre del expediente</th>
                    <th># Adjunto</th>
                    <th>Nombre adjunto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" class="rowstyle">
                    <td>{{ item.est_cdgo }}</td>
                    <td>{{ item.caj_cdgo }}</td>
                    <td>{{ item.lgj_nmro }}</td>
                    <td>{{ item.documento }}</td>
                    <td>{{ item.dcm_asunto }}</td>
                    <td>{{ item.adjuntos.adj_nmro }}</td>
                    <td>{{ item.adj_path }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>
            <div v-if="showProgress" style="text-align:center">
              <v-progress-circular :size="50" :width="5" color="blue" indeterminate></v-progress-circular>
            </div>
          </div>
          <br>
          <div class="row justify-content-around" align="center">
            <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
            <button type="button" id="customXLSButton" class="btn btn-primary"
              v-on:click="exportReport">Exportar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDateUtc } from '../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: {
    //VProgressCircular
  },
  data() {
    return {
      message: '',
      items: [],
      estantes: [],
      debug: null,
      fecha: null,
      sal_nmbre: '',
      showProgress: false,
    }
  },
  filters: {
    formatDateUtc
  },
  computed: {
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function () {
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    if (this.$route.params.query.sal_id) {
      this.fetchEstante();
    }
    this.debug = this.$route.params;
    this.sal_nmbre = this.$route.params.sal_nmbre;
    this.searchItems(this.$route.params.query);
  },
  mounted: function () {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchEstante() {
      let uri = '/estantes/sala';
      this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: this.$route.params.query.sal_id }).then((response) => {
        this.estantes = response.data;
      });
    },
    searchItems(p) {
      if (p != null) {
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/adjuntos_faltantes';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
          .then((response) => {
            var list = response.data;

            for (let i = 0; i < list.length; i++) {
              list[i].documento = list[i].tpocor.tcr_cdgo + '-' + list[i].dcm_ano + '-' + list[i].dcm_cdgo;
              let adj_path = list[i]?.adjuntos?.adj_nmbre;
              if (list[i]?.adjuntos?.adj_path) {
                adj_path = list[i]?.adjuntos?.adj_path;
                debugger;
              }
              list[i].adj_path = adj_path;

              for (let j = 0; j < this.estantes.length; j++) {
                if (list[i]?.caja) {
                  if (list[i].caja?.est_id == this.estantes[j]._id) {
                    list[i].est_cdgo = this.estantes[j].est_cdgo;
                    j = this.estantes.length;
                  }
                }
              }
            }

            this.items = list;

            this.showProgress = false;
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al buscar los documentos' + err;
          });
      }
    },
    printReport(object) {
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
        "<!DOCTYPE html>" +
        "<html>" +
        "<head>" +
        "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>" +
        "<link rel='stylesheet' href='../../css/report.css'></link>" +
        "</head>" +
        "<body>" +
        report.innerHTML +
        "</body>" +
        "</html>");
      p.document.close();

      setTimeout(function () { //giving it 200 milliseconds time to load
        p.focus();
        p.print();
        p.close();
      }, 2000);
    },
    exportReport() {
      var tableId = 'reporteAdjuntosFaltantes';

      var report = [];
      for (var i = 0; i < this.items.length; i++) {
        let item = {};
        item.est_cdgo = this.items[i].est_cdgo;
        item.caj_cdgo = this.items[i].caj_cdgo;
        item.lgj_nmro = this.items[i].lgj_nmro;
        item.documento = this.items[i].documento;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.adj_nmro = this.items[i].adjuntos.adj_nmro;
        item.adj_path = this.items[i].adj_path;

        report.push(item);
      }

      var headersT = ["Estante", "Caja", "Inventario", "Documento", "Nombre del expediente", "# Adjunto", "Nombre adjunto"];

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
        ["Reporte de archivos adjuntos faltantes"],
        ["Sala: " + this.sal_nmbre],
        [],
        headersT
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
@import '../../../public/css/report.css';
</style>
